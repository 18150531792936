body {
  height: 100%;
}
input,
textarea,
*:focus:not([data-focusvisible-polyfill='true']) {
  outline: none;
}

*:focus[data-focusvisible-polyfill='true'] {
  outline-color: #ab935a;
}

a {
  color: #ab935a;
  text-decoration: none;
}
